






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'
import { DataSet } from '@antv/data-set'

@Component({
  props: {
    id: String
  }
})
export default class ASComponentsReportManagerPie extends Vue {
  @Prop()
  id: string
  @Prop()
  reportData: any[]
  @Prop()
  color: string
  @Prop()
  series: any[]

  dataList: any[] = []
  
  mounted () {
    this.drawChart()
  }
  // 数据整理
  dataSettle (data, series) {
    data.map((item, index) => {
      this.dataList.push({
        item: series[index],
        count: item
      })
    })
  }
  async drawChart () {
    await this.dataSettle(this.reportData, this.series)
    const COLOR = ['#53dbad', '#69dbe6', '#e9dc80', '#f3c395']
    const { DataView } = DataSet
    const dv = new DataView()
    dv.source(this.dataList).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    })
    const chart: any = new G2.Chart({
      container: this.id,
      forceFit: true,
      width: 380,
      height: 320,
      padding: 'auto'
    })
    chart.source(dv, {
      percent: {
        formatter: val => {
          val = (val * 100) + '%'
          return val
        }
      }
    })
    chart.coord('theta', {
      radius: 0.75
    })
    chart.legend('gender', {
      position: 'bottom'
    })
    chart.tooltip({
      showTitle: false,
      itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
    })
    chart.intervalStack()
      .position('percent')
      .color('item', COLOR)
      .tooltip('item*percent', (item, percent) => {
        percent = (percent * 100).toFixed(2) + '%'
        return {
          name: item,
          value: percent
        }
      })
      .style({
        lineWidth: 1,
        stroke: '#fff'
      })
    chart.render()
  }
}
