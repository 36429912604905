






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'
import { DataSet } from '@antv/data-set'

@Component({
  props: {
    id: String
  }
})
export default class ASComponentsReportManagerPie extends Vue {
  @Prop()
  id: string
  @Prop()
  reportData: number
  @Prop()
  color: string
  
  mounted () {
    this.drawChart()
  }

  drawChart () {
    let data = [
      { value: Number(this.reportData), name: '完成度' },
      { value: 100 - Number(this.reportData), name: '未完成度' }
    ]
    const { DataView } = DataSet
    const chart: any = new G2.Chart({
      container: this.id,
      forceFit: true,
      height: 190,
      padding: 0
    })
    // 外部
    const outterView = chart.view()
    const dv = new DataView()
    dv.source(data).transform({
      type: 'percent',
      field: 'value',
      dimension: 'name',
      as: 'percent'
    })
    outterView.source(dv, {
      percent: {
        formatter: val => {
          val = (val * 100).toFixed(2) + '%'
          return val
        }
      }
    })
    chart.guide().html({
      position: [ '50%', '50%' ],
      html: `<div style="text-align: center; width: 10em;"><span style="color:#484848; font-size:25px; font-weigh: bold;">${this.reportData}%</span></div>`,
      alignX: 'middle',
      alignY: 'middle'
    })
    outterView.coord('theta', {
      innerRadius: 0.6 / 0.75,
      radius: 0.75
    })
    outterView.intervalStack()
      .position('percent')
      .color('name', [this.color, '#f4f4f4'])
      .tooltip(false)
      .select(false)
      .style({
        lineWidth: 0,
        stroke: '#fff'
      });
      chart.render()
  }
}
