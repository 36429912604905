






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'
import { DataSet } from '@antv/data-set'
import Brush from '@antv/g2-brush'

@Component({
  props: {
    id: String
  }
})
export default class ASComponentsReportManagerPie extends Vue {
  @Prop()
  id: string
  @Prop()
  reportData: any[]
  @Prop()
  color: string
  @Prop()
  series: any[]

  dataList: any[] = []
  
  mounted () {
    this.drawChart()
  }
  async drawChart () {
    let data = []
    this.reportData.map((item, index) => {
      data.push({
        name: this.series[index],
        value: item
      })
    })
    let ds = new DataSet()
    const dv2 = ds.createView('bar', {
      watchingStates: [ 'name', 'value' ]
    }).source(data)
    let chart: any = new G2.Chart({
      container: this.id,
      forceFit: true,
      height: 280
    })
    chart.source(dv2)
    chart.scale({
      name: {
        tickInterval: 5
      }
    })
    chart.axis('name', {
      label: {
        offset: 10,
        // 设置文本的显示样式，还可以是个回调函数，回调函数的参数为该坐标轴对应字段的数值
        textStyle: {
          textAlign: 'end', // 文本对齐方向，可取值为： start center end
          fontSize: '12', // 文本大小
          rotate: 30, 
          textBaseline: 'bottom' // 文本基准线，可取 top middle bottom，默认为middle
        },
        autoRotate: false,
        formatter(text, item, index) {
          if (text.length > 6) {
            return text.slice(0, 6) + '...'
          } else {
            return text
          }
        }
      }
    })
    chart.scale('name', {
      type: 'cat', 
      tickCount: 8,   // 设置坐标轴上刻度点的个数
    })
    chart.interval().position('name*value').color('#5bc8d7')
    chart.render()

    new Brush({
      canvas: chart.get('canvas'),
      chart: chart,
      type: 'X',
      onBrushstart: function onBrushstart() {
        chart.hideTooltip()
      },
      onBrushmove: function onBrushmove() {
        chart.hideTooltip()
      }
    })
    chart.on('plotdblclick', function() {
      chart.get('options').filters = {}
      chart.repaint()
    })
  }
    // await this.dataSettle(this.reportData, this.series)
    // data.map((item, index) => {
    //   this.dataList.push({
    //     name: series[index],
    //     value: item
    //   })
    // })
  //   let list = [{
  //     name: "hahhaha", value: 60}, {
  //     name: "你你你你你", value: 40}]
  //   console.log(this.dataList)
  //   let ds = new DataSet()
  //   const dv2 = ds.createView('bar', {
  //     watchingStates: [ 'name', 'value' ]
  //   }).source(list)
  //   const chart = new G2.Chart({
  //     container: this.id,
  //     forceFit: true,
  //     height: 280
  //   })
  //   chart.source(dv2)
  //   chart.scale('value', {
  //     tickInterval: 20
  //   })
  //   chart.scale('name', {
  //     type: 'cat', 
  //     tickCount: 8, // 设置坐标轴上刻度点的个数
  //   })
  //   chart.axis('name', {
  //     label: {
  //       offset: 10,
  //       // 设置文本的显示样式，还可以是个回调函数，回调函数的参数为该坐标轴对应字段的数值
  //       textStyle: {
  //         textAlign: 'end', // 文本对齐方向，可取值为： start center end
  //         fontSize: '12', // 文本大小
  //         rotate: 250, 
  //         textBaseline: 'middle' // 文本基准线，可取 top middle bottom，默认为middle
  //       },
  //       autoRotate: false,
  //       formatter(text, item, index) {
  //         if (text.length > 6) {
  //           return text.slice(0, 6) + '...'
  //         } else {
  //           return text
  //         }
  //       }
  //     }
  //   })
  //   chart.tooltip(true, {
  //     showTitle: false
  //   })
  //   chart.legend(false)
  //   chart.interval()
  //     .position('name*value')
  //     .color('name', [this.color])
  //   chart.render()
  //   new Brush({
  //     canvas: chart.get('canvas'),
  //     chart,
  //     type: 'X',
  //     onBrushstart() {
  //       chart.hideTooltip()
  //     },
  //     onBrushmove() {
  //       chart.hideTooltip()
  //     }
  //   })
  //   chart.on('plotdblclick', () => {
  //     console.log('hahha')
  //     chart.get('options').filters = {}
  //     chart.repaint()
  //   })
  // }
}
