







































import Vue from 'vue'
import Component from 'vue-class-component'
import integrity from '@/components/common/Pie.vue'
import tendency from '@/components/common/Line.vue'
import subjectPie from '@/components/common/FullPie.vue'
import { Getter, Action } from 'vuex-class'

@Component({
  components: {
    integrity,
    tendency,
    subjectPie
  }
})
export default class ATSViewsReportIndex extends Vue {
  @Getter('distribution') distribution: any[]
  @Getter('completion') completion: any[]
  @Getter('completionSum') completionSum: any[]
  @Action('caseDetailReport') caseDetailReport: Function

  project: any = {}
  PIECOLOR: any[] = ['#5bc8d7', '#56d6ab', '#e8cd64']
  loading: boolean = false
  mounted () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.getReportData()
  }
  getReportData () {
    this.loading = true
    let params = {
      projectId: this.project.id
    }
    this.caseDetailReport({ params }).then(() => {
      this.loading = false
    })
  }
}
